<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统参数配置</el-breadcrumb-item>
        <el-breadcrumb-item>模板分类列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          class=""
          icon="el-icon-plus"
          size="medium"
          type="primary"
          @click="Add()"
          >新增模板分类</el-button
        >
      </div>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="监管参数配置名称"
              clearable
              v-model="page.page_config_name"
              size="medium"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.state"
              clearable
              placeholder="全部"
              @change="onSubmit(page.state)"
              size="medium"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.company_type_id"
              clearable
              placeholder="主体类型"
              size="medium"
              @change="onSubmit(page.company_type_id)"
            >
              <el-option
                v-for="(item, index) in tagOptions"
                :key="index"
                :label="item.company_tag_name"
                :value="String(item.company_type_id)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="监管参数配置名称" clearable v-model="page.page_config_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.state" clearable placeholder="全部"  @change="onSubmit(page.state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.company_type_id" clearable placeholder="主体类型"  @change="onSubmit(page.company_type_id)">
                <el-option
                  v-for="(item,index) in tagOptions"
                  :key="index"
                  :label="item.company_tag_name"
                  :value="String(item.company_type_id)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.page_module_id" clearable placeholder="参数分类名称"  @change="onSubmit(page.page_module_id)">
                <el-option
                  v-for="(item,index) in statusOptions1"
                  :key="index"
                  :label="item.page_module_name"
                  :value="String(item.page_module_id)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="2" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>

          <el-table-column
            prop="page_config_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="参数配置名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="company_tag_name"
            min-width="150"
            :show-overflow-tooltip="true"
            label="主体类型"
            align="center"
          ></el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.state === 1 ? 'c67c' : 'ce6a'"
                >{{ scope.row.state | statusFilter }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="page_module_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="	页面参数分类名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name_add"
            min-width="120"
            :show-overflow-tooltip="true"
            label="添加人员"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="添加时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name_update"
            min-width="120"
            :show-overflow-tooltip="true"
            label="更新人员"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="update_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="更新时间"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="100"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                v-if="user.user_code == scope.row.user_code_add"
                type="text"
                class="orange"
                size="medium"
                @click="godetail(scope.row.page_config_id)"
                >编辑</el-button
              >
              <el-button
                v-if="user.user_code == scope.row.user_code_add"
                type="text"
                class="disib"
                :class="{ red: scope.row.state == 1 }"
                size="mini"
                @click="changeState(scope.row)"
                >{{ scope.row.state | antiStateFilter }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        page_module_name: "",
        page_module_id: "",
        company_type_id: "",
        page_config_name: "",
        state: "",
      },
      statusOptions: [
        { label: "全部", value: "" },
        { label: "启用", value: "1" },
        { label: "禁用", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      statusOptions1: [],
      tagOptions: [],
      tableData: [],
      user: "",
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用",
      };
      return statusMap[status];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用",
      };
      return stateMap[state];
    },
  },
  created() {
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.user = local.get("user");
    this.getModule();
    this.getTag();
    this.init(this.page);
  },
  methods: {
    getTag() {
      var that = this;
      axios.get("/pc/company-tag/all", { company_type_id: "" }).then((v) => {
        that.tagOptions = v.data.company_tag_list;
      });
    },
    getModule() {
      axios.get("/pc/page-module/all").then((v) => {
        this.statusOptions1 = v.data.page_module_list;
      });
    },
    init(params) {
      axios.get("/pc/page-config/list", params).then((v) => {
        this.tableData = v.data.page_config_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init(this.page);
    },
    godetail(index) {
      session.set("params", { id: index, name: "" });
      this.$router.push({
        name: "TemplateAdd",
      });
    },
    Add() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "TemplateAdd" });
    },
    changeState(row) {
      let { page_config_id, state } = row;
      state = state == 1 ? 2 : 1;
      axios
        .put("/pc/page-config/state/update", { page_config_id, state })
        .then((response) => {
          this.init(this.page);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
